
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setXFTData, setXFTSign } from '@/utils/cookies'

@Component({
  name: 'xftlogin',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public loginQueryData: string = '';
    @Provide() public loginQuerySign: string = '';

    public async created () {
      // 薪福通-门户会在returnUrl上拼接&data=#{data}&sign=#{sign}进行回调
      this.loginQueryData = getCurrentQuery('data')
      this.loginQuerySign = getCurrentQuery('sign')
      const OrderdetailUrl = getCurrentQuery('OrderdetailUrl')
      console.log('OrderdetailUrl', OrderdetailUrl)
      if (OrderdetailUrl) {
        location.replace('https://' + decodeURIComponent(OrderdetailUrl))
      } else if ((this.loginQueryData && this.loginQuerySign)) {
        this.authorize()
      } else {
        this.doAuth()
      }
    }

    @Emit()
    public async doAuth () {
      try {
        const res = await httpHelper.get({
          url: 'ThirdLogin/Sdk',
          data: {
            identity: 'http://xinfutong.benlai.com'
          }
        })
        if (res.code === 0) {
          console.log(res)
          location.replace(res.value.expand.loginUrl)
        }
      } catch (err) {
        console.log('err', err)
        this.$toasted.show(err.msg || err.message)
      }
    }

    @Emit()
    public async authorize () {
      setXFTData(this.loginQueryData)
      setXFTSign(this.loginQuerySign)
      const data: any = {
        data: this.loginQueryData,
        sign: this.loginQuerySign
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/xinfutong',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        console.log(res)
        if (res.code === 0) {
          location.replace(res.value.extend.xft_lifeUrl)
        } else {
          this.$toasted.show(res.msg || res.message)
          this.goTo(res, 9, 24)
        }
      } catch (err) {
        console.log(err)
        this.$toasted.show(err.msg || err.message)
        this.goTo(err, 9, 24)
      }
    }
}
